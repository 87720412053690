/*
 * @Author: your name
 * @Date: 2020-07-01 09:05:05
 * @LastEditTime: 2020-07-01 15:51:26
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \pcweb\src\service\order.js
 */ 
import { get, post, upload } from "..//common/query";

export const GET_ORDER = data => get("/order/all", data);
export const EVALUATE = data => post("/order/addevaluation", data);
export const ENSURE_RECEIVE = data => post("/order/sign", data);
export const CANCEL_ORDER = data => post("/order/cancel", data);
export const GODADDY = data => post("/order/refund", data);
export const ORDER_DETAIL = data => get("/order/info", data);
export const ORDER_PAYSUCCESS = data => get("/order/checkOrderStatus", {...data, notMessage: true});
export const GET_LOGISTICS = data => get("/logistics/getinfo", data);
export const GET_ORDERZS = data => get("/order/getTZS", data);
export const UPLOAD = data => upload("/files/uploadfiles", data);
export const ORDER_AFFIRM = data => post("/order/affirm", data);
export const ORDER_GETCOINFO = data => post("/order/getCoInfo", data);
export const DEL_ORDER = data => post("/order/del", data);
export const msgconsulting = data => post("/msgconsulting/add", data);//  

export const cu = data => get("/cu/getVerifyCode", data);//验证码

