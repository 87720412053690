<template>
  <div class="model">
    <div class="model-b">
      <img src="@/assets/images/pay/成功.png" alt="">
      <p class="success-text">支付成功</p>
      <p class="look" @click="lookOrder">查看订单</p>
      <img class="close-img" @click="close" src="@/assets/images/pay/close.png" alt="">
    </div>
    
  </div>
</template>

<script>
export default {
  props: {
    orderId: {
      type: [Number, String],
      default: () => ''
    }
  },

  methods: {
    close() {
      this.$emit('update:show', false)
    },

    lookOrder() {
      this.$router.push({
        path: '/orderdetail',
        query: {
          orderId: this.orderId
        }
      })
    }
  },
 
}
</script>

<style lang="less" scoped>
.model {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
  .model-b {
    width: 520px;
    height: 444px;
    border-radius: 20px;
    background: #ffffff;
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    img {
      width: 100%;
    }
    .success-text {
      font-size: 34px;
      color: #666666;
    }
    .look {
      width: 248px;
      height: 74px;
      text-align: center;
      line-height: 74px;
      font-size: 31px;
      background: #921d22;
      color: #ffffff;
      margin-top: 80px;
      cursor: pointer;
    }
    .close-img {
      width: 60px;
      height: 60px;
      position: absolute;
      top: -120px;
      right: -60px;
      cursor: pointer;
    }
  }
  
}
</style>