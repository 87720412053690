<template>
  <div class="order-process">
    <div class="title">订单流程</div>
    <div class="process-list">
      <div class="process-item" v-for="item in processList" :key="item.id">
        <div class="img">
          <img :src="item.img" alt />
        </div>
        <div class="num">{{ item.id }}</div>
        <div :class="{ name: true, passed: item.state }">{{ item.name }}</div>
        <div class="child-process" :class="{ name: name, passeds: item.state }">
          <div class="icon">
            <div
              class="icon-item"
              v-for="(i, index) in item.process"
              :key="index"
            >
              <i class="point"></i>
            </div>
          </div>
          <div class="txt">
            <span v-for="(subItem, index) in item.process" :key="index">
              {{ subItem }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <PaySuccessModel :orderId="orderId" :show.sync="show" v-if="show"/>
  </div>
</template>
<script>
import PaySuccessModel from "./components/PaySuccessModel"
import { ORDER_PAYSUCCESS } from "@/service/order";
export default {
  name: "OrderProcess",
  data() {
    return {
      show: false,
      orderId: '',
      name: false,
      processList: [
        {
          id: "01",
          img: "/img/order-process1.png",
          name: "提交订单",
          process: ["接收样本包", "采集头发样本"]
        },
        {
          id: "02",
          img: "/img/order-process2.png",
          name: "回寄样本",
          process: ["寄出样本包", "收到样本包"]
        },
        {
          id: "03",
          img: "/img/order-process3.png",
          name: "碳化培育",
          process: ["钻石成长", "送检认证"]
        },
        {
          id: "04",
          img: "/img/order-process3.png",
          name: "钻石配送",
          process: ["确认签收", "完成订单"]
        }
      ]
    };
  },

  components: {
    PaySuccessModel
  },

  created() {
    let { orderno, paytype, orderid } = this.$route.query
    if(paytype == 0 || paytype == 1) {
      this.processList[0].state = true
      this.name = false
    }
    if(paytype == 2) {
      this.processList.map((val, index) => {
        if(index !== 3) {
          val.state = true
          this.name = true
        }
      })
    }
    this.orderId = orderid
    ORDER_PAYSUCCESS({type: paytype, orderno: orderno}).then(res => {
      if(res.status === 200) {
        this.show = true
      }
    })
  },
  methods: {}
};
</script>
<style lang="less" scoped>
.order-process {
  width: 1200px;
  margin: 0 auto;
  .title {
    line-height: 123px;
    color: #000;
    font-size: 28px;
    border-bottom: 1px solid #cfcfcf;
  }
  .process-list {
    display: flex;
    justify-content: center;
    padding: 60px 0;
    margin-bottom: 200px;
    .process-item {
      margin-right: 84px;
      width: 146px;
      .img {
        width: 146px;
        height: 146px;
        margin-bottom: 20px;
        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
      .num {
        line-height: 40px;
        font-size: 22px;
      }
      .name.passed {
        color: #921d22;
      }
      .name.passeds {
        span {
          color: #921d22;
        }
        .point {
          color: #921d22 !important;
          border-color: #921d22 !important;
        }
      }
      .name {
        color: #000;
        font-size: 17px;
        line-height: 28px;
      }
      .child-process {
        display: flex;
        margin-top: 4px;
        .icon:first-child {
          margin-top: 9px;
        }
        .icon {
          height: 24px;
          width: 7px;
          margin-right: 8px;
          .icon-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: 2px;
          }
          .icon-item::after {
            width: 1px;
            height: 13px;
            background: #999;
            content: "";
          }
          .icon-item:last-child::after {
            display: none;
          }
          .point {
            width: 7px;
            height: 7px;
            margin-bottom: 2px;
            border-radius: 50%;
            box-sizing: border-box;
            border: 1px solid #999;
          }
        }
        .txt {
          display: flex;
          flex-direction: column;
          line-height: 24px;
          font-size: 14px;
          color: #999;
        }
      }
    }
  }
}
</style>
